body {
	margin: 0;
	padding: 0;
    overscroll-behavior: none;
    font-family: 'Nanum Myeongjo', serif;
    color: #fff;
    background: #050505;
    scroll-behavior:smooth;
    overflow: hidden;
}

.lds-roller {
	width: 80px;
	height: 80px;
	position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
	z-index: 5;
  }
  .lds-roller div {
	animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	transform-origin: 40px 40px;
  }
  .lds-roller div:after {
	content: " ";
	display: block;
	position: absolute;
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background:#f9f0ec;
	margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
	animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
	top: 63px;
	left: 63px;
  }
  .lds-roller div:nth-child(2) {
	animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
	top: 68px;
	left: 56px;
  }
  .lds-roller div:nth-child(3) {
	animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
	top: 71px;
	left: 48px;
  }
  .lds-roller div:nth-child(4) {
	animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
	top: 72px;
	left: 40px;
  }
  .lds-roller div:nth-child(5) {
	animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
	top: 71px;
	left: 32px;
  }
  .lds-roller div:nth-child(6) {
	animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
	top: 68px;
	left: 24px;
  }
  .lds-roller div:nth-child(7) {
	animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
	top: 63px;
	left: 17px;
  }
  .lds-roller div:nth-child(8) {
	animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
	top: 56px;
	left: 12px;
  }
  @keyframes lds-roller {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }

#loading-text-intro{
    z-index: 3;
    position: absolute;
    width: 100vw;
    height: 100%;
    font-family: 'Inter', sans-serif;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: 100;
	color:#f9f0ec;
	background: radial-gradient(circle at center center,#5d5d5d 0,#090909 58%);
}

#loading-text-intro.ended{
	transform: translateY(200%);
}

nav { width: 100%; padding: 1rem; position: fixed;  z-index: 2;}

span {
	display: inline-block;
	pointer-events: none;
	transition: transform 0.1s linear;
}

.cursor { 
	pointer-events: none;
	position: fixed; 
    top: 10px; 
    left: 10px;
	padding: 10px;
	background-color: #fff;
	border-radius: 50%; 
	transform: translate(-50%, -50%);
	mix-blend-mode: difference;
	transition: transform .8s ease, opacity 0.6s ease;
	z-index: 2;
}

/* .cursor:has(.a:hover){
    transform: translate(-50%, -50%) scale(4); opacity: 0.3;
} */

.a { 
	display: inline-block; 
	color: #fff; 
	padding: 1rem;
    margin-right: 4rem;
    letter-spacing: .4em;
    font-size: .9em;
	transition: all 0.3s ease, color 0.3s ease;
}

nav.header .a:hover{
    cursor: pointer;
    color: rgb(175, 175, 175); 
    transform: scale(1.1); 

}

nav.header .a:hover ~ .cursor {
    transform: translate(-50%, -50%) scale(5); opacity: 0.1; 
}

.dg.ac {
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	z-index: 2 !important; /* TODO Solve this in HTML */
}

#canvas-container{
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    outline: none;
    z-index: 0;
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    background: #000;
    background: radial-gradient(circle at center center,#171717 0,#050505 58%);
}

#canvas-container-details{
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    outline: none;
    z-index: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    pointer-events: all;
    overflow: hidden;
}


.section{
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* display: flex; */
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    padding-left: 5%;
    padding-right: 5%;
    z-index: 1;
    width: 65%;
    height: 100vh;
    pointer-events: none;
}
.resaltado-vv {
	font-family: 'Josefin Sans', sans-serif;
	font-size: 1.0em;
	font-weight: 700;
	letter-spacing: -0.1em;
	word-spacing: 0.6em;
}

.header {
    position: absolute;
    top: -2em;
    left: 0;
    color: #fff;
    font-size: .8em;
    width: 100%;
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    z-index: 2;
    opacity: 0;
    transition: all 1.9s ease-in-out ;
    padding: 0;
    margin: 0;

}
.header.ended{
    top: 3em;
    opacity: 1;
}

.header>span {
    padding: 0 3.25em;
    letter-spacing: .4em;
    position: relative;
    
}

.header>span.active:after, .first {
    position: absolute;
    left: 50%;
    -webkit-transform: translate3d(-50%,0,0);
    transform: translate3d(-50%,0,0);
}

.header>span.active:after {
    content: "";
    bottom: -10px;
    width: 20px;
    height: 2px;
    background: #fff;
}

.first {
    pointer-events: none;
    font-size: 2em;
    letter-spacing: .5em;
    text-align: center;
    width: 100%;
    display: flex;
    height: 120vh;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;

    -moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	position: relative;
	z-index: 1;
    background: linear-gradient(0deg, rgba(5,5,5,1) 20%, rgba(5,5,5,0) 50%);

}
.aka {
  font-size: 0.55em;
  font-weight: bold;
}

.first>h2 {
    font-size: 0.65em;
    line-height: 0;
    font-weight: 100;
    letter-spacing: .25em;
    font-style: italic;
    margin: 35px;
}
.first>h1{
    margin: 10px 0;
    font-weight: 100;

    letter-spacing: 0.4em;

}

.first>h1:after {
    content: "";
    position: absolute;
    margin-top: 105px;
    left: calc(50% - 25px);
    width: 50px;
    height: 2px;
    background: #ffffff70;
}

.first>p{
    font-size: 14px;
    letter-spacing: 0px;
    font-family: sans-serif;
    width: 700px;
    line-height: 28px;
    font-weight: lighter;
    margin-top: 50px;
    margin-bottom: 100px;
    color: #fff339;
    opacity: 0;
    transition: all 3.9s ease-in-out ;
}

.first>p.ended{
    opacity: 1;
}

.second{
    pointer-events: all;
    font-size: 2em;
    width: 100%;
    display: flex;
    height: 100vh;
    background: #141414;
    z-index: 1;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.second-container{
    pointer-events: all;
    width: 100%;
    display: flex;
    height: 100vh;
    margin: 0;
    padding: 0 10%;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
    background: radial-gradient(circle at 90% center, rgba(5,5,5,0) 30%, #141414 70%);

}

.second-container>ul{
    list-style: none;
    display: inline-flex;
    padding: 0px;
    margin: 0px 0px 30px 60px;
    font-family: "DM Sans", sans-serif;
    color:#ffffff60;
    z-index: 2;
}

.second-container>ul>li.active:after {
    content: "";
    top: 20px;
    width: 50px;
    height: 2px;
    background: #fff;
    position: relative;
    left: 0px;
    display: block;
}


.second-container>ul>li{
    padding-right: 20px;
    transition: all 0.8s ease-out;
}

.second-container>ul>li:hover{
    color:whitesmoke;
    pointer-events: all;
    cursor: pointer;
}

.second-container>ul>li:hover ~ nav.header.ended.cursor{
    transform: translate(-50%, -50%) scale(5); opacity: 1; 
}

.second-container>ul>li.active{
    color:whitesmoke;
}

.second-container>p{
    font-size: 14px;
    font-family: sans-serif;
    line-height: 24px;
    width: 30%;
    color: #fff339;
    margin-left: 60px;
    height: 200px;
}

.third{
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-image: url('../static/assets/LADEMO_background1(02-08).png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

}

.third>h1{
    font-size: 15vw;
    font-weight: 100;
    letter-spacing: -6px;
    color:whitesmoke;
    margin: 40px 0;
    padding: 0;
}

.third::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(5, 5, 5, 0.7); /* Optional: adds a dark overlay to ensure text readability */
    z-index: 1;
}

.third>p{
    column-count: 2;
    column-gap: 40px;
    width: 80%;
    font-size: 14px;
    font-family: "DM Sans", sans-serif;
    font-weight: 100;
    line-height: 28px;
    height: auto;
    color: #fff339;
    z-index: 2;
}

@media (max-width: 768px) { /* Ajustes para dispositivos con pantallas pequeñas */
    .third > p {
        column-count: 1; /* Cambia a una columna en pantallas pequeñas */
        width: 80%; /* Asegura que el contenido ocupe el ancho disponible */
        font-size: 14px; /* Ajusta el tamaño de la fuente si es necesario */
        line-height: 24px; /* Ajusta la altura de línea para mejorar la legibilidad */
    }
}

.footer{
    border: none;
    width: 100%;
    margin-bottom: 40px;
    opacity: 1;
    font-family: "DM Sans", sans-serif;
    font-size: 10px;
    transition: all 0.1s ease-out 0.1s;
    bottom: 0;
    color: #8c00b7ff;
    font-weight: 100;
    opacity: 1;
    text-align: center;
}

.footer:hover {
    color: white;
    opacity: 1;
}

.footer a{
    text-decoration: none;
    pointer-events: auto;
    color: white;
}

/*/////////////////wordpress 6.6.1 */

.footer-group {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center; 
}

.footer-item {
  display: inline-block; /* Coloca los elementos en fila */
  padding: 8px;
}

.footer-item>figure>a>img {
  width: 34px;
  height: auto;
  filter: invert(18%) sepia(96%) saturate(3905%) hue-rotate(280deg) brightness(102%) contrast(101%);
}


@media only screen and (max-width: 660px) {

    .a{
        padding: 10px;
        margin-right: 0rem;
        letter-spacing: 0.3em;
    }

    .footer{
        margin-bottom: 20px;
    }
    .header>span{
        padding: 0 1em;
    }

    .header{
        font-size: .6em;
    }

    .main-section .product-display h3{
        width: 260px;
        font-size: 42px;
        margin-left: 30px;
        line-height: 45px;
    }
    .first>h2 {
        font-size: 0.65em;
        line-height: 1;
        font-weight: 100;
        letter-spacing: .25em;
        font-style: italic;
        margin: 35px;
    }
    .first>h1{
        margin: 10px 0;
        font-weight: 100;
        letter-spacing: 0.2em;
        font-size: 13vw;
    }

    .first>p{
        width: 85%;
        line-height: 22px;
    }

    .second-container {
        padding: 0;
        justify-content: flex-end;
    }

    .second-container>ul {
        margin: 0px 0px 30px 30px;
        width: 80%;
    }

    .second-container>ul>li {
        padding-right: 20px;
        transition: all 0.8s ease-out;
        font-size: 20px;
    }

    .second-container>p {
        width: 85%;
        margin-left: 30px;
        line-height: 21px;
        margin-bottom: 40px;
    }

    .third>p {
        column-count: 1;
    }
}

.demo-link {
    display: inline-block; /* Changed from block to inline-block */
    margin-top: 1.2rem;
    cursor: pointer;
    text-decoration: none;
    color: whitesmoke;
    font-size: 0.85em;
}

.demo-link .ver-demo {
    position: relative;
    display: inline-block;
    cursor: pointer;
    transition: color 0.3s ease;
}

.demo-link:hover .ver-demo {
    color: #4a90e2; /* Add hover color */
}



/* styles for lightbox */
.lightbox {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.lightbox-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
}

.close-lightbox {
    position: absolute;
    top: -40px;
    right: -40px;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

#vimeo-video {
    max-width: 100%;
    max-height: 80vh;
}

